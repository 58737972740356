
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService as _usrService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/models/Country';
import { Role } from 'src/app/models/Role';
import { User } from 'src/app/models/User';
import { storageHelper } from 'src/app/modules/shared/helpers/storage-helpers';
import { CountryService } from 'src/app/services/country.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selectedRole: Role = new Role();
  authUser: User = new User();
  @Input() public nav?: MatSidenav;
  constructor(
    private _usrService: _usrService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private authService: AuthService,
    private countryService:CountryService
  ) {
  }

  selectedLang!: any;

  languages = [
    { id: 0, value: 'fr', },
    { id: 1, value: 'en' }
  ];

  ngOnInit() {
    this._usrService._user.asObservable().subscribe(user => {
      this.authUser = new User(user);
      if (this.authUser.id) {
        this.selectedRole = new Role(storageHelper.getObject('selectedRole'));
        if (!this.selectedRole?.id || !this.authUser.hasRole(this.selectedRole)) {
          this.changeRole(this.authUser.lmtRoles[0]);
        }
        else{
          this.userService._user.next(this.authUser);
        }
        this.selectedLang = localStorage.getItem('lang') || this.translateService.getDefaultLang() || navigator.language.substring(0, 2);
      }
    });

  }

  changeRole(role: Role) {
    this.userService.changeRole(role);
    this.selectedRole = role;
    if (this.selectedRole.isAdmin()) {
      this.router.navigate(['/administration']);
    } else {
      if(this.selectedRole.isFO()){
        this.router.navigate(['tenders/tenders/fuel-releases']);
      }
      if( this.selectedRole.isBO()){
        this.router.navigate(['/administration/pricing']);
      }
      if(this.selectedRole.isAM()){
        this.countryService.listByUser(this.authUser.id,true).subscribe(res => {
          storageHelper.setObject('countryAM', [this.calcCountry(res)]);
         });
      }
      if(this.selectedRole.isCZ()){
        this.countryService.listByUser(this.authUser.id,false).subscribe(res => {
          storageHelper.setObject('countryCZ', [this.calcCountry(res)]);
          storageHelper.setObject('countriesCZ',res);
         });
      }
      if (['AM', 'IS', 'CM', 'CZ'].indexOf(role.name) > -1) {
              this.router.navigate(['tenders/tenders/' + role.name]);
      }
    }
  }

  updateLang(lang: string) {
    this.translateService.currentLang = lang;
    this.translateService.setDefaultLang(lang)
    localStorage.setItem('lang', lang);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  calcCountry(countries : Country[]):string{
    if(null !==countries && countries.length !=0){
        return countries[0]?.name;
    }
    return '';
  }

}
