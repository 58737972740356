import { APP_INITIALIZER, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule } from '@ff/at-internet';

import { environment } from '../../../environments/environment';
import { createTranslateLoader, SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { routes } from './core.routes';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserModule, USER_CONFIG } from '@ff/user';
import { AuthModule, AUTH_CONFIG } from '@ff/auth';
import { OAUTH2_CONFIG, OAuth2Module } from '@ff/oauth2';
import { UserService } from 'src/app/services/user.service';
import { fetchUserFactory } from 'src/app/initiliziers/user.initializer';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SessionService } from '../shared/services/session.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LoginComponent } from './components/login/login.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
        HomeComponent,
        ForbiddenComponent,
        NotFoundComponent,
        LoginComponent
    ],
    imports: [
        // Angular Packages
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
        // App packages
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
        // FF packages
        ATInternetModule,
        AuthModule,
        OAuth2Module,
        UserModule
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL'],
                },
                display: {
                    dateInput: 'DD-MMMM-y',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: AUTH_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    logout: '/security/user/v1/logout'
                }
            }
        },
        {
            provide: USER_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    fetch: '/security/user/v1/getCurrentUserInfo'
                }
            }
        },
        {
            provide: OAUTH2_CONFIG,
            useValue: {
                host: environment.api.host,
                endpoints: {
                    bearer: '/oauth2/token',
                    providers: '/oauth2/providers'
                }
            }
        },
        {
            provide: API_CONFIG,
            useValue: {
                host: environment.api.host
            }
        },
        { provide: LOCALE_ID, useValue: 'en-GB' }
        // {
        //     provide: LOCALE_ID,
        //     deps: [SessionService],
        //     useFactory: (sessionService: SessionService) => sessionService.getLocale()
        // }


    ],
    bootstrap: [
        AppComponent
    ]
})
export class CoreModule {
    public constructor(@Optional() @SkipSelf() parent: CoreModule, private translate: TranslateService, private adapter: DateAdapter<Date>,) {
        if (parent) {
            throw new Error('CoreModule should not be exported then imported by another module!');
        }
        this.translate.setDefaultLang(localStorage.getItem('lang') || this.translate.getDefaultLang() || navigator.language.substring(0, 2));
        this.adapter.setLocale(this.translate.getDefaultLang());
        this.translate.onDefaultLangChange.subscribe(({ lang }: LangChangeEvent) => {
            this.adapter.setLocale(lang);
        });
    }
}
