import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@ff/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderModule, LoaderService } from '@ff/loader';
import { ThemeModule } from '@ff/theme';
import { MaterialModule } from './material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UppercaseFirstLetterPipe } from './pipes/uppercasefirstletter.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { FormatNumberDisplayDirective } from './directives/format-number-display.directive';
import { FormatPercentageDisplayDirective } from './directives/format-percentage-display.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MyMatPaginatorIntl } from './helpers/my-mat-paginator-intl';
import { Location } from '@angular/common';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { LocalDatePipe } from './pipes/localDate.pipe';
import { LocalNumberPipe } from './pipes/localNumber.pipe';
import { MatInputCommifiedDirective } from './directives/mat-input-commified.directive';
import { LocalizedNumericInputDirective } from './directives/localized-numeric-input.directive';
import { LogService } from './services/log.service';
import { NgxFileDropModule } from 'ngx-file-drop';
@NgModule({
    declarations: [
        UppercaseFirstLetterPipe,
        DialogComponent,
        FormatNumberDisplayDirective,
        FormatPercentageDisplayDirective,
        DragAndDropDirective,
        FormSubmitDirective,
        ControlErrorComponent,
        UppercaseFirstLetterPipe,
        EnumToArrayPipe,
        LocalDatePipe,
        LocalNumberPipe,
        MatInputCommifiedDirective,
        LocalizedNumericInputDirective
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl },
        LogService
    ],
    imports: [
        HttpClientModule,
        // Angular
        ReactiveFormsModule,
        CommonModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,
        // Angular Material
        MaterialModule,
        FormsModule,
        TranslateModule,
        MatCheckboxModule,
        NgxFileDropModule
    ],
    exports: [
        ReactiveFormsModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        ThemeModule,
        FormsModule,
        // Angular Material
        MaterialModule,
        UppercaseFirstLetterPipe,
        EnumToArrayPipe,
        TranslateModule,
        MatCheckboxModule,
        LocalDatePipe,
        LocalNumberPipe,
        FormatNumberDisplayDirective,
        MatInputCommifiedDirective,
        LocalizedNumericInputDirective,
        NgxFileDropModule
    ]
})
export class SharedModule {
    public constructor() { }
}



export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

export function loaderShow(_loaderService: LoaderService) {
    if (!_loaderService.isVisible()) {
        _loaderService.show();
    }
}

export function loaderHide(_loaderService: LoaderService) {
    if (_loaderService.isVisible()) {
        _loaderService.hide();
    }
}