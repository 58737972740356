import { Component, Host, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Route, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Role } from 'src/app/models/Role';
import { UserService } from 'src/app/services/user.service';


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    fullAccessMode = false;
    private readonly _routes: Route[] = [

    ];
    private _alive: Subject<any>;
    currentRole: Role = new Role();
    constructor(
        @Host() @Optional() private _sidenav: MatSidenav,
        public _router: Router,
        private userService: UserService,
    ) {
        this._alive = new Subject();
        this._routes = _router.config;
        // this._router.events.subscribe(val => console.log(val));
    }

    ngOnInit(): void {
        this.userService._selectedRole.asObservable().subscribe(role => {
            this.currentRole = role;
        });
    }

    public getRoutes(): Route[] {
        return this._routes;
    }

    public close(): void {
        if (this._sidenav) {
            this._sidenav.close();
        }
    }

    ngOnDestroy() {
        this._alive.next(true);
        this._alive.complete();
    }

    displayForAdminOrIsm() {
        return (this.fullAccessMode || this.currentRole.isAdmin() || this.currentRole?.isISM()) ? true : false;
    }

    displayForAdminOrIsmOrFo() {
        return (this.fullAccessMode || this.currentRole.isAdmin() || this.currentRole?.isISM()) || this.currentRole?.isFO() ? true : false;
    }

    displayForIsmOrCm() {
        return (this.fullAccessMode || this.currentRole?.isISM() || this.currentRole?.isCM()) ? true : false;
    }

    displayForAdminOrCm() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isCM()) ? true : false;
    }

    displayForAdminOrBo(){
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isBO())? true : false;
    }

    displayForAdminOrBoOrFoOrAmOrIsm() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isBO() || this.currentRole?.isFO() || this.currentRole?.isAM() || this.currentRole?.isISM()) ? true : false;
    }

    displayForAdminOrBoOrFoOrIsm() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isBO() || this.currentRole?.isFO() || this.currentRole?.isISM()) ? true : false;
    }

    displayForAdminOrAm() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isAM()) ? true : false;
    }

    displayForAdminOrAmOrCz() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isAM() || this.currentRole?.isCZ()) ? true : false;
    }

    displayForAdmin() {
        return (this.fullAccessMode || this.currentRole?.isAdmin()) ? true : false;
    }

    displayAdministration() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isCM() || this.currentRole?.isISM() || this.currentRole?.isBO() || this.currentRole?.isFO() || this.currentRole?.isAM() || this.currentRole?.isCZ()) ? true : false;
    }

    displayForAdminOrBoOrFo() {
        return (this.fullAccessMode || this.currentRole?.isAdmin() || this.currentRole?.isBO() || this.currentRole?.isFO()) ? true : false;
    }

    displayForFo() {
        return (this.fullAccessMode || this.currentRole?.isFO()) ? true : false;
    }
}
