export class Currency {
  id: number;
  currencyName: string;
  currencyCode: string;

  constructor(data: any = {}) {
    this.id = data?.id;
    this.currencyName = data?.currencyName;
    this.currencyCode = data?.currencyCode;
  }
}