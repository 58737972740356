import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/Country';
import { CountryPricing } from '../models/CountryPricing';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends XhrService {
  public API_NAME: String = environment.api.host + '/api/v1';
  modelRoutePlural = "/lmt-countries";
  modelRouteSingel = "/lmt-country";
  countryPricingRoute = "/lmt-country-pricing";
  constructor(private http: HttpClient, router: Router, authService: AuthService) {
    super(router, authService);
  }

  list(param: string = ''): Observable<Country[]> {
    return this.http.get(this.API_NAME + this.modelRoutePlural + (param.length ? '/' + param : '')).pipe(
      map(res => (res as []).map(item => new Country(item))),
      catchError(this.handleError.bind(this))
    );
  }

  listByUser(identifier: number,isAm:boolean): Observable<Country[]> {
    return this.http.get(this.API_NAME + this.modelRoutePlural + `/${identifier}/${isAm}`).pipe(
      map(res => (res as []).map(item => new Country(item))),
      catchError(this.handleError.bind(this))
    );
  }

  show(identifier: number): Observable<Country> {
    return this.http.get(this.API_NAME + this.modelRouteSingel + `/${identifier}`).pipe(
      map(res => new Country(res)),
      catchError(this.handleError.bind(this))
    );
  }

  showPricing(identifier: number): Observable<CountryPricing> {
    return this.http.get(this.API_NAME + this.countryPricingRoute + `/without-pricing/${identifier}`).pipe(
      map(res => new CountryPricing(res)),
      catchError(this.handleError.bind(this))
    );
  }

  store(country: Country) {
    return this.http.post(this.API_NAME + this.modelRouteSingel, country).pipe(
      catchError(this.handleError.bind(this))
    );
  }


  editPricing(countryPricing: CountryPricing) {
    return this.http.put(this.API_NAME + this.countryPricingRoute + `/${countryPricing.id}`, countryPricing).pipe(
      catchError(this.handleError.bind(this))
    );
  }


}
