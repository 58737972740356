import { Routes } from '@angular/router';
import { LoggedInGuard } from '../shared/guard/loggedIn.guard';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';


export const routes: Routes = [
  {
    path: 'analyse',
    loadChildren: () => import('../analyse/analyse.module').then(m => m.AnalyseModule),
    canActivate: [LoggedInGuard],
    data: { title: 'Analyse' }
  },
  {
    path: 'tenders',
    loadChildren: () => import('../tenders/tenders.module').then(m => m.TendersModule),
    canActivate: [LoggedInGuard],
    data: { title: 'Tenders' }
  },
  {
    path: 'administration',
    loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [LoggedInGuard],
    data: { title: 'Administration' }
  },
  { path: 'login', component: LoginComponent },
  { path: '403', component: ForbiddenComponent, pathMatch: 'full' },
  { path: '404', component: NotFoundComponent, pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
