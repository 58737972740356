import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    text: string;
    value: string;
    type: string;
    icon: string;
    error: string;
    list: any[];
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    text_ok!:string;
    text_yes!: string;
    text_no!: string;
    button_save!: string;
    button_cancel!: string;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }

    ngOnInit() {
        this.text_ok = 'Ok';
        this.text_yes = 'Validate';
        this.text_no = 'Cancel';
        this.button_save = 'Valider';
        this.button_cancel = 'Cancel';
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onClick(): void {
        this.dialogRef.close(this.data.value);
    }
}
