import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {


    getLocale() {
        return 'en-GB';
        const lang = localStorage.getItem('lang');
        switch (lang) {
            case 'fr':
                return 'fr-FR';
            case 'en':
                return 'en-GB';
            default:
                return 'fr-FR';
        }
    }
}