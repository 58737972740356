export class Country {
  id: number;
  name: string;
  codeIso3166Alpha2: string;
  zone: string;
  iataContinent: string;



  constructor(data: any = {}) {
    this.id = data?.id
    this.name = data?.name;
    this.codeIso3166Alpha2 = data?.codeIso3166Alpha2;
    this.zone = data?.zone;
    this.iataContinent = data?.iataContinent

  }
}