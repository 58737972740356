export class Role {
  id: number
  name: string;
  shortDescription: string;
  description: string;


  constructor(data: any = {}) {
    this.id = data?.id
    this.name = data?.name;
    this.shortDescription = data?.shortDescription;
    this.description = data?.description;
  }

  isISM() {
    return this.name === 'IS' ? true : false;
  }

  isCM() {
    return this.name === 'CM' ? true : false;
  }

  isAM() {
    return this.name === 'AM' ? true : false;
  }

  isCZ() {
    return this.name === 'CZ' ? true : false;
  }

  isAdmin() {
    return this.name === 'AD' ? true : false;
  }

  isBO() {
    return this.name === 'BO' ? true : false;
  }

  isFO() {
    return this.name === 'FO' ? true : false;
  }

  isSU() {
    return this.name === 'SU' ? true : false;
  }

  getMappedName() {
    switch (this.name) {
      case 'IS':
        return 'ISM';
      case 'AM':
        return 'AM';
      case 'CM':
        return 'CM';
      case 'CZ':
        return 'CZ';
      default:
        return 'ISM';
    }
  }


}