import { Country } from "./Country";
import { Currency } from "./Currency";
import { FinancialPlace } from "./financial-place";
import { User } from "./User";

export class CountryPricing {
  id: number;
  lmtCountry?: Country;
  lmtCurrency?: Currency;
  localCurrency?:Currency;
  lmtFinancialPlace?: FinancialPlace;
  am?: User[];
  cz?: User[];
  purchaseSale:boolean;
  teaManages:boolean;
  mandate:number;
  constructor(data: any = {}) {
    this.id = data?.id
    this.lmtCountry = data?.lmtCountry ? new Country(data?.lmtCountry) : undefined;
    this.lmtCurrency = data?.lmtCurrency ? new Currency(data?.lmtCurrency) : undefined;
    this.localCurrency = data?.localCurrency ? new Currency(data?.localCurrency) : undefined;
    this.lmtFinancialPlace = data?.lmtFinancialPlace ? new FinancialPlace(data?.lmtFinancialPlace) : undefined;
    this.am = data?.am ? data?.am.map((a:User) =>new User(a) ): undefined;
    this.cz = data?.cz ? data?.cz.map((c:User) =>new User(c) ) : undefined;
    this.purchaseSale=data?.purchaseSale;
    this.teaManages=data?.teaManages;
    this.mandate=data?.mandate;
  }
}

