/**
  * Usage: number | localNumber:'format'
  * If no format is provided 2 decimals will be used.
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { SessionService } from '../services/session.service';

@Pipe({
    name: 'localNumber',
})
export class LocalNumberPipe implements PipeTransform {

    constructor(private session: SessionService) { }

    transform(value: any, format: string = '.0-2') {
        if (value == null || isNaN(value)) { return value; } // !value would also react to zeros.


        const stringValue = value.toString();
        const decimalSignIndex = stringValue.indexOf('.');
        let formated = formatNumber(value, 'fr-FR', format);
        let result = (formated.indexOf(',') > -1) ? formated.substring(formated.indexOf(','), (formated.length + 1)) : '';
        let count = 0;
        for (let index = (decimalSignIndex > -1 ? decimalSignIndex : stringValue.length); index >= 0; index--) {
            const element = stringValue[index];
            if (!isNaN(element)) {
                result = element + result;
                count++;
                if (count === 3) {
                    result = ' ' + result;
                    count = 0;
                }
            }
        }
        if (result[0] === ' ') {
            result = result.substring(1, result.length);
        }
        return result;
    }
}