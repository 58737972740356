
export class storageHelper {

    public static getObject(index: string) {
        return localStorage.getItem(index) != undefined ? JSON.parse(localStorage.getItem(index) || '') : null;
    }

    public static setObject(index: string, object: any) {
        localStorage.setItem(index, JSON.stringify(object));
    }

    public static clearObject(index: string) {
        localStorage.removeItem(index);
    }

}

