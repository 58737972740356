<div class="container-fluid h-100 d-flex flex-column">
    <div class="row justify-content-center px-4">
        <form class="login-form d-flex align-items-center justify-content-center" novalidate>
            <mat-card class="col-12 col-md-8 col-lg-6 col-xl-5 p-5">
                <mat-card-content class="d-flex flex-column align-items-center">
                    <div class="logo-holder">
                        <img src="assets/lmt-logo.png" title="TotalEnergies" alt="TotalEnergies" class="app-logo" />
                    </div>
                    <ng-container *ngIf="getOAuth2Providers() | async">
                        <ng-container *ngFor="let provider of getOAuth2Providers() | async" class="d-flex align-items-center justify-content-center ff-link">
                            <button *ngIf="displayProvider(provider)" mat-raised-button class="align-self-center mb-4 px-3 py-1" color="primary" (click)="loginWithOAuth2(provider.url)">
                                <mat-icon>arrow_right</mat-icon>
                                {{'general_labels.connect_via' | translate}} {{provider.label}}
                            </button>
                        </ng-container>
                    </ng-container>

                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>