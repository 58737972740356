import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { errorObj } from '../interfaces/errorObj';

@Injectable({
  providedIn: 'root'
})
export class XhrService {
  static modelRoute = '/';
  constructor(private router: Router, private authService: AuthService) { }


  protected handleError<T>(error: HttpErrorResponse): Promise<any> {
    console.log(error)
    console.error('An error occurred', error); // for demo purposes only
    if ((error.status === 0 && error.statusText === 'Unknown Error') || error.status === 401) {
      this.authService.logout();
      this.router.navigateByUrl('/login');
      return Promise.reject({ message: 'Your token is probably expired. Please login again' });
    } else if (error.error instanceof Blob) {
      return (error.error as Blob).text().then((message) => {
        throw JSON.parse(message);
      });
    } else {
      return Promise.reject(error.error);
    }
  }
}