import { Client } from "./Client";
import { Country } from "./Country";
import { Role } from "./Role";

export class User {
  id: number;
  igg: string;
  firstName: string;
  lastName: string;
  mail: string;
  lmtRoles: Role[] = [];
  deletedAt: any;
  isActive: boolean;
  selectedRole: any;
  lmtEntity: any;

  lmtClients: Client[] = [];
  lmtIsmFo: User[] = [];
  isCreditManagerSpoc: boolean;
  amCountries: Country[] = [];
  czCountries: Country[] = [];

  constructor(data: any = {}) {
    this.id = data?.id;
    this.igg = data?.igg;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.mail = data?.mail;
    this.deletedAt = data?.deletedAt;
    this.isActive = data?.isActive;
    this.selectedRole = data?.selectedRole;
    this.lmtEntity = data?.lmtEntity;
    this.isCreditManagerSpoc = data?.isCreditManagerSpoc
    this.lmtIsmFo = data?.lmtIsmFo;
    this.amCountries = data?.amCountries;
    this.czCountries = data?.czCountries;
    this.lmtClients = data?.lmtClients;

    for (let index = 0; index < data?.lmtRoles?.length; index++) {
      const element = data?.lmtRoles[index];
      this.lmtRoles.push(new Role(element));
    }
  }

  hasRole(role: Role) {
    const res = this.lmtRoles?.find((item: Role) => item.id === role.id);
    return (res) ? true : false;
  }

  hasRoleByRoleName(roleName: string) {
    const role = this.lmtRoles.find((role: Role) => role.name === roleName);
    return (role) ? true : false;
  }
}