import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@ff/auth';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {


  constructor(public authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isLoggedIn().getValue()) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}

