export const environment = {
  production: false,
  api: {
    host: 'https://backend-gw-lmtv2-d.mpshp.alzp.tgscloud.net'
  },
  oauth2: {
    digitalpass: {
      enabled: true,
      endpoint: '/oauth2/digitalpass'
    }
  }
};
