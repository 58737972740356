import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataRole } from 'src/app/models/DataRole';
import { DataCountries } from 'src/app/models/DataCountries';
import { DataUser } from 'src/app/models/DataUser';
import { environment } from 'src/environments/environment';
import { DataClient } from 'src/app/models/DataClient';
import { XhrService } from './xhr.service';
import { Country } from '../models/Country';
import { Client } from '../models/Client';
import { User } from '../models/User';
import { storageHelper } from '../modules/shared/helpers/storage-helpers';
import { Role } from '../models/Role';
import { Router } from '@angular/router';
import { OAuth2Service } from '@ff/oauth2';
import { LogService } from '../modules/shared/services/log.service';
import { AuthService } from '@ff/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService extends XhrService {
  _user = new BehaviorSubject<User>(new User);
  _selectedRole = new BehaviorSubject<Role>(new Role(storageHelper.getObject('selectedRole')));

  public API_NAME: String = environment.api.host + '/api/v1';

  constructor(private http: HttpClient, private oAuth2Service: OAuth2Service, private logger: LogService, router: Router, authService: AuthService) {
    super(router, authService);
  }

  public auditUser(id: number) {
    return this.http.get(this.API_NAME + '/lmt-user/' + id + '/audit').pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public getRoles(): Observable<Role[]> {
    return this.http.get<DataRole>(this.API_NAME + '/lmt-roles').pipe(
      map((res: any) => res.map((item: any) => new Role(item))),
      catchError(this.handleError.bind(this))
    );
  }

  public getClients(name: string) {
    return this.http.get<DataClient>(this.API_NAME + '/lmt-clients/' + name).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  public getClientId(id: number) {
    return this.http.get<Client>(this.API_NAME + '/lmt-client/' + id).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public delete(id: number) {
    return this.http.delete<DataUser>(this.API_NAME + '/lmt-user/' + id).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  public searchUserAgil(data: string) {
    return this.http.get<DataUser>(this.API_NAME + '/lmt-users/search/info/' + data).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  public create(body: any) {
    return this.http.post<DataUser>(this.API_NAME + '/lmt-user', body).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public update(id: number, body: any) {
    return this.http.put(this.API_NAME + '/lmt-user/' + id, body).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public getUserById(id: number): Observable<User> {
    return this.http.get<User>(this.API_NAME + '/lmt-user/' + id).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public transferWallet(body: any) {
    return this.http.get(this.API_NAME + '/lmt-client/transfer-wallet/' + body.origin + '/' + body.target).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  public getAMAndCZMails(tenderId: number): Observable<string[]> {
    return this.http.get<User>(this.API_NAME + '/lmt-users-mail?tenderId=' + tenderId).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  listUsers(params: any = {}): Observable<any> {
    let queryParams: any = {}
    if (params.page)
      queryParams.page = params.page;
    if (params.size)
      queryParams.size = params.size;
    if (params.sortedField)
      queryParams.sortedField = params.sortedField;
    if (params.sortOrder)
      queryParams.sortOrder = params.sortOrder;
    if (params.searchValue)
      queryParams.searchValue = params.searchValue;
    if (params.igg)
      queryParams.igg = params.igg;
    if (params.lastName)
      queryParams.lastName = params.lastName;
    if (params.firstName)
      queryParams.firstName = params.firstName;
    if (params.mail)
      queryParams.mail = params.mail;
    if (params.role)
      queryParams.role = params.role;
    if (params.amCountries)
      queryParams.amCountry = params.amCountries;
    if (params.czCountries)
      queryParams.czCountry = params.czCountries;
    if (params.isAll)
      queryParams.isAll = params.isAll;
    if (params.isActive)
      queryParams.isActive = params.isActive;
    return this.http.get<User>(this.API_NAME + `/lmt-users?`, { params: queryParams }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  countUsers(params: any = {}): Observable<number> {
    let queryParams: any = {}
    if (params.page)
      queryParams.page = params.page;
    if (params.size)
      queryParams.size = params.size;
    if (params.sortedField)
      queryParams.sortedField = params.sortedField;
    if (params.sortOrder)
      queryParams.sortOrder = params.sortOrder;
    if (params.searchValue)
      queryParams.searchValue = params.searchValue;
    if (params.igg)
      queryParams.igg = params.igg;
    if (params.lastName)
      queryParams.lastName = params.lastName;
    if (params.firstName)
      queryParams.firstName = params.firstName;
    if (params.role)
      queryParams.role = params.role;
    if (params.amCountries)
      queryParams.amCountry = params.amCountries;
    if (params.czCountries)
      queryParams.czCountry = params.czCountries;
    return this.http.get(this.API_NAME + `/lmt-users/count?`, { params: queryParams }).pipe(
      map(res => Number(res)),
      catchError(this.handleError.bind(this))
    );
  }

  exportUsers(params: any = {}): Observable<any> {
    let queryParams: any = {}
    if (params.page)
      queryParams.page = params.page;
    if (params.size)
      queryParams.size = params.size;
    if (params.sortedField)
      queryParams.sortedField = params.sortedField;
    if (params.sortOrder)
      queryParams.sortOrder = params.sortOrder;
    if (params.searchValue)
      queryParams.searchValue = params.searchValue;
    if (params.igg)
      queryParams.igg = params.igg;
    if (params.lastName)
      queryParams.lastName = params.lastName;
    if (params.firstName)
      queryParams.firstName = params.firstName;
    if (params.role)
      queryParams.role = params.role;
    if (params.amCountries)
      queryParams.amCountry = params.amCountries;
    if (params.czCountries)
      queryParams.czCountry = params.czCountries;
    return this.http.get(this.API_NAME + `/lmt-users/export`, { observe: 'response', responseType: 'blob',params: queryParams }
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getUser(): User {
    return this._user.getValue();
  }

  current(): Observable<any> {
    const url = environment.api.host + `/security/user/v1/getCurrentUserInfo`;
    return this.http.get(url).pipe(
      map((res: any) => { return new User(res) }),
      catchError(this.handleErrorCurrentUser.bind(this))
    );
  }

  changeRole(role: Role) {
    storageHelper.setObject('selectedRole', role);
    this._selectedRole.next(role)
  }


  protected handleErrorCurrentUser<T>(error: any): Promise<any> {
    this.logger.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.error);
  }
}

