import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'uppercasefirstletter' })
export class UppercaseFirstLetterPipe implements PipeTransform {
    transform(word: string): string {
        return word && word.length ? word[0].toUpperCase() + word.slice(1) : word;
    }
}