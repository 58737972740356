<footer class="pe-5 pt-5">
  <div class="d-flex justify-content-between">
    <div class="footer"></div>
    <div class="footer">
      <a href="assets/docs/GTC.docx" target="_blank">
        General Terms and Conditions
      </a>
      <span> | </span>
      <a href="assets/docs/Clipboards or free text boxes.pdf" target="_blank">
        Personal data: Clipboards or free text boxes
      </a>
      <span> | </span>
      <a href="https://totalworkplace.sharepoint.com/:w:/s/BIGBIRDUSERS-UserGuide/EXvnLDtiM1RLgwTuaxpTkdQB3khr_hQTz7waSz4oA-UxCQ" target="_blank">
        User Guide
      </a>
      <span> | </span>
      <a href="https://totalworkplace.sharepoint.com/:w:/s/BIGBIRDUSERS-UserGuide/Ef-nJJtoK-JMmKShaRxRPgEBGJVcNdlcz_NrcJ-Ahfg1fg?e=AeLNMy" target="_blank">
        FAQ
      </a>
    </div>

    <div class="footer">
      @Copyright TotalEnergies
    </div>
  </div>


</footer>
