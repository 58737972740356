import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ATInternetService } from '@ff/at-internet';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(
    public atInternet: ATInternetService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerSvgIcon();
  }

  private registerSvgIcon() {
    this.matIconRegistry.addSvgIcon(
      `total-bin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/bin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `total-edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `total-user`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `total-union`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/union.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `add`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `search`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `arrow`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `edit`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `trash`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/trash.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `warning`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `timer_off`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/timer-off.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `swap_calls`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/swap-calls.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `plusSquare`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/plus-square.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `more`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/more.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `download`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/download.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `upload`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/upload.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `file`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/file.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `arrow-down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/arrow-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `comment`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `cancel`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cancel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `comment-disabled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/comment-disabled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `status`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/status.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `visibility`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/visibility.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `visibility-white`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/visibility-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `copy`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/copy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `calendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `export`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/export.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `import`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/import.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `document`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/document.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `clock`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/clock.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `clock-disabled`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/clock-disabled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `mail`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/mail.svg')
    );
  }
}
