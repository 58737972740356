<header>
    <img src="assets/logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo me-4 pointer" routerLink="/" />
    <ng-container *ngIf="authUser.id">
        <div class="header-right">
            <div class="header-top">
                <span class="me-auto header-title" routerLink="/">
                    <img src="assets/lmt-logo.png" title="TotalEnergies" alt="TotalEnergies" class="app-logo pointer" />
                </span>
              <div class="user-badge-container">
                   <span class="navigation-btn">
                        <mat-icon color="primary" class="me-1 mb-1" svgIcon="total-user"></mat-icon>
                        <span *ngIf="authUser?.lmtEntity?.name" class="username me-1">{{authUser?.lmtEntity?.name}}
                        </span>
                    </span>
              </div>
                <div class="user-badge-container">
                    <span [matMenuTriggerFor]="menu" class="navigation-btn">
                        <mat-icon color="primary" class="me-1 mb-1" svgIcon="total-union"></mat-icon>
                        <span class="username me-1">View as : {{selectedRole.shortDescription}}
                        </span>
                        <mat-icon color="primary" class="me-1 mb-1" svgIcon="arrow-down"></mat-icon>
                    </span>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                        <ng-container *ngFor="let role of authUser?.lmtRoles">
                            <button (click)="changeRole(role)" style='font-size: 12px;' mat-menu-item>{{role.shortDescription}}
                            </button>
                        </ng-container>

                    </mat-menu>
                </div>
                <div class="user-badge-container">
                    <span class="navigation-btn">
                        <mat-icon color="primary" class="me-1 mb-1" svgIcon="total-user"></mat-icon>
                        <span *ngIf="authUser?.firstName" class="username me-1">{{authUser?.firstName}}
                        </span>
                    </span>
                </div>

                <div class="logout-btn-container pointer" #tooltip="matTooltip" matTooltipClass="lmt-mat-tooltip" [matTooltip]="('general_labels.logout' | translate)">
                    <mat-icon (click)="logout()" color="primary">logout</mat-icon>
                </div>
            </div>

            <div style="display: inline-flex;">
                <app-navigation class="me-auto"></app-navigation>
                <mat-select class="lang ml-auto" #langSelect [(ngModel)]="selectedLang" (selectionChange)="updateLang($event.value)" style="width: 50px">
                    <mat-option *ngFor="let lang of languages" [value]="lang.value">
                        {{ lang.value | uppercase }}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </ng-container>

</header>
