import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OAuth2Service } from '@ff/oauth2';
/*import { UserService } from '@ff/angular-core';*/
import { FormGroup, FormBuilder } from '@angular/forms';
/*import { ResetPasswordLinkComponent } from '../reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from '../reset-password/resetPassword.component';*/
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '@ff/loader';
import { UserService as _userService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/models/Role';
import { User } from 'src/app/models/User';
import { storageHelper } from 'src/app/modules/shared/helpers/storage-helpers';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm!: FormGroup;
  public error: any;

  /**
   * Reset password link modal
   */
  public showResetPasswordLinkModal!: boolean;

  /**
   * Reset password modal
   */
  public showResetPasswordModal!: boolean;

  public token!: string;
  selectedRole: Role = new Role();
  authUser?: User;
  constructor(
    /*private _authService: AuthService,*/
    fb: FormBuilder,
    private router: Router,
    private _userService: _userService,
    private userService: UserService,
    private loaderService: LoaderService,
    private oAuth2Service: OAuth2Service,
    private translateService: TranslateService
  ) {
    this.error = {};
  }

  public loginWithOAuth2(url: string) {

    this.loaderService.show();
    // window.location.href = url;
    this.oAuth2Service.login(url);
  }

  public getOAuth2Providers(): BehaviorSubject<any> {
    return this.oAuth2Service.getProviders();
  }

  ngOnInit() {
    this._userService._user.asObservable().subscribe(user => {
      const currentRole = new Role(storageHelper.getObject('selectedRole'));
      if (user?.id && currentRole.isAdmin()) {
        this.router.navigate(['/administration']);
      } else if (user?.id) {
        if(currentRole.isFO()){
          this.router.navigate(['tenders/tenders/fuel-releases']);
        }
        else if(currentRole.isBO()){
          this.router.navigate(['/administration/pricing']);
        }
        else{
          this.router.navigate(['/tenders']);
        }
        
      } else {
        localStorage.removeItem('_token');
      }
    });
  }

  displayProvider(provider: any) {
    /*
    Deux provider sont configuré coté API pour DIGITAL Pass
    La difference entre les deux est la redirect_url_after_login
    L'une redirige vers l'url du web
    l'autre redirige vers la cordova-plugin-customurlscheme de l'application mobile (https://github.com/EddyVerbruggen/Custom-URL-scheme)
    */
    // Utilisez cette configuration pour vous connecter depuis l'application mobile
    if (provider.id === 'digitalpass_mobile') {
      return false;
    } else {
      return true;
    }

  }

}
