<nav class="align-items-end desktop">
    <ul class="ps-0">
        <ng-container *ngFor="let route of getRoutes()">
            <ng-container *ngIf="route.data?.['title']">
                <!-- Has children -->
                <ng-container *ngIf="route.data?.['title']=== 'Analyse'">
                    <li class="me-3" [matMenuTriggerFor]="menu" [ngClass]="{'active': _router.url.includes('analyse')}" routerLinkActive="active">
                        {{ 'dashboard.analyse' | translate | uppercasefirstletter}}
                    </li>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                        <ul>
                            <li>
                                <a class="link" [routerLink]="route.path + '/contract' ">
                                    <button class="menuItem" mat-menu-item>
                                        {{ 'dashboard.contract' | translate | uppercasefirstletter}}  
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/exposure'">
                                    <button *ngIf="displayForIsmOrCm()" class="menuItem" mat-menu-item>
                                        {{ 'dashboard.exposure' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </mat-menu>
                </ng-container>
                <!-- No child -->
                <ng-container *ngIf="route.data?.['title']=== 'Tenders' && !displayForAdminOrBoOrFo()">
                    <a class="link" [routerLink]="route.path + '/tenders/'+currentRole.name">
                        <li class="me-3" routerLinkActive="active">TENDERS</li>
                    </a>
                </ng-container>
                 <!-- Has children -->
                 <ng-container *ngIf="route.data?.['title']=== 'Tenders' && displayForFo()">
                    <li class="me-3" [matMenuTriggerFor]="menu" [ngClass]="{'active': _router.url.includes('tenders')}" routerLinkActive="active">
                        TENDERS
                    </li>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                        <ul>
                            <li>
                                <a class="link" [routerLink]="route.path + '/tenders/fuel-releases' ">
                                    <button class="menuItem" mat-menu-item>
                                        Contract Data & Fuel Request  
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </mat-menu>
                </ng-container>
                <!-- Has children -->
                <ng-container *ngIf="route.data?.['title']=== 'Administration' && displayAdministration()">
                    <li class="me-3" [matMenuTriggerFor]="menu" [ngClass]="{'active': _router.url.includes('administration')}" routerLinkActive="active">
                        ADMINISTRATION
                    </li>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false">
                        <ul>
                            <li>
                                <a class="link" [routerLink]="route.path + '/users' ">
                                    <button class="menuItem" *ngIf="displayForAdmin()" mat-menu-item>
                                        {{ 'administration.users.users' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/clients'">
                                    <button *ngIf="displayForAdminOrIsmOrFo()" class="menuItem" mat-menu-item>
                                        {{ 'administration.client_groups.buyer' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/client-groups' ">
                                    <button *ngIf="displayForAdminOrIsm()" class="menuItem" mat-menu-item>
                                        {{ 'administration.client_groups.buyer_group' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/document-templates' ">
                                    <button *ngIf="displayForAdmin()" class="menuItem" mat-menu-item>
                                        {{ 'administration.templates.documents_templates' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/pricing' ">
                                    <button *ngIf="displayForAdminOrBoOrFoOrAmOrIsm()" class="menuItem" mat-menu-item>
                                        {{ 'administration.operator.pricing' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/valorisation-credit' ">
                                    <button *ngIf="displayForAdminOrCm()" class="menuItem" mat-menu-item>
                                        {{ 'credit_offer.referenced_credit' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/cost-build-up' ">
                                    <button *ngIf="displayForAdminOrAmOrCz()" class="menuItem" mat-menu-item>
                                        {{ 'cost_build_up.title' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                            <li>
                                <a class="link" [routerLink]="route.path + '/sold-to-geographique-zones' ">
                                    <button *ngIf="displayForAdminOrBoOrFoOrIsm()" class="menuItem" mat-menu-item>
                                        {{ 'geographique_zones.title' | translate | uppercasefirstletter}}
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </ng-container>
    </ul>
</nav>